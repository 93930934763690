<template>
  <div>
    <div class="field">
      <p class="title is-4 has-text-dark">Get down to business</p>
      <p class="subtitle has-text-grey-dark is-6">
        What is your organisation, and how do we contact you?
      </p>
      <p class="is-size-7 has-text-grey-dark">
        All fields are required
      </p>
    </div>
    <div class="field">
      <input
        placeholder="Company name"
        type="text"
        v-model="form.name"
        :class="{ 'is-danger': errors.has('name') }"
        v-validate.disable="'required'"
        name="name"
        class="input"
      />
      <span class="help has-text-danger" v-if="errors.has('name')">
        {{ errors.first('name') }}
      </span>
    </div>
    <div class="field">
      <input
        placeholder="Company's website"
        type="text"
        v-model="form.website"
        :class="{ 'is-danger': errors.has('website') }"
        v-validate.disable="'required'"
        name="website"
        class="input"
      />
      <span class="help has-text-danger" v-if="errors.has('website')">
        {{ errors.first('website') }}
      </span>
    </div>
    <div class="field">
      <input
        placeholder="Phone number"
        type="text"
        v-model="form.phone"
        :class="{ 'is-danger': errors.has('phone') }"
        v-validate.disable="'required'"
        name="phone"
        class="input"
      />
      <span class="help has-text-danger" v-if="errors.has('phone')">
        {{ errors.first('phone') }}
      </span>
    </div>
    <div class="field">
      <div class="field">
        <div v-if="utils.showSearch">
          <div class="field has-addons">
            <p class="control is-expanded">
              <input
                placeholder="Enter postcode"
                type="text"
                v-model="search.query"
                class="input control is-expanded"
              />
            </p>
            <p class="control">
              <button
                @click.prevent="getAddressData"
                class="button is-info"
                :class="{ 'is-loading': $wait.is('getAddress') }"
              >
                <span>Search</span>
              </button>
            </p>
          </div>
          <a
            class="help has-text-link"
            @click="utils.manualAddressInput = !utils.manualAddressInput"
          >
            {{ manualAddressEntryText }}
          </a>
        </div>
        <div v-else>
          <div class="field" id="postcode">
            <MultiSelect
              v-model="utils.vSelectPostcode"
              :options="search.results"
              :show-labels="false"
              :close-on-select="true"
              :allow-empty="false"
              track-by="label"
              @select="completeForm"
              label="label"
            />
          </div>
          <p class="help">
            <span>Postcode: {{ search.query.toUpperCase() }} (</span>
            <a class="has-text-info" @click="utils.showSearch = true">edit</a>
            <span>)</span>
          </p>
        </div>
      </div>
    </div>
    <div class="field" v-show="utils.manualAddressInput">
      <input
        placeholder="Company address"
        type="text"
        v-model="form.address1"
        :class="{ 'is-danger': errors.has('address1') }"
        v-validate.disable="'required'"
        name="address1"
        data-vv-as="address"
        class="input"
      />
      <span class="help has-text-danger" v-if="errors.has('address1')">
        {{ errors.first('address1') }}
      </span>
    </div>
    <div class="field" v-show="utils.manualAddressInput">
      <input
        placeholder="Company address"
        type="text"
        v-model="form.address2"
        name="address2"
        class="input"
      />
    </div>
    <div class="field" v-show="utils.manualAddressInput">
      <input
        placeholder="Company postcode"
        type="text"
        v-model="form.postcode"
        :class="{ 'is-danger': errors.has('postcode') }"
        v-validate.disable="'required'"
        name="postcode"
        class="input"
      />
      <span class="help has-text-danger" v-if="errors.has('postcode')">
        {{ errors.first('postcode') }}
      </span>
    </div>
    <div class="field" v-show="utils.manualAddressInput">
      <input
        placeholder="City"
        type="text"
        v-model="form.city"
        :class="{ 'is-danger': errors.has('city') }"
        v-validate.disable="'required'"
        name="city"
        class="input"
      />
      <span class="help has-text-danger" v-if="errors.has('city')">
        {{ errors.first('city') }}
      </span>
    </div>
    <div class="field" v-show="utils.manualAddressInput">
      <input
        placeholder="Country"
        type="text"
        v-model="form.country"
        :class="{ 'is-danger': errors.has('country') }"
        v-validate.disable="'required'"
        name="country"
        class="input"
      />
      <span class="help has-text-danger" v-if="errors.has('country')">
        {{ errors.first('country') }}
      </span>
    </div>
    <hr />
    <div class="field is-grouped is-grouped-right">
      <div class="control">
        <span class="button is-text" @click.prevent="$emit('back')">
          Back
        </span>
      </div>
      <div class="control">
        <button
          class="button is-info"
          :class="{ 'is-loading': $wait.is('loading') }"
          @click.prevent="submit"
        >
          Continue
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { getAddress } from '@/plugins/axios'
export default {
  name: 'AuthSignupOrg',
  components: {
    MultiSelect: () => import('vue-multiselect')
  },
  data() {
    return {
      form: {
        name: '',
        website: '',
        phone: '',
        address1: '',
        address2: '',
        city: '',
        country: '',
        postcode: '',
        source: 'signup'
      },
      search: {
        query: '',
        results: []
      },
      utils: {
        manualAddressInput: false,
        error: false,
        showSearch: true,
        vSelectPostcode: ''
      }
    }
  },
  computed: {
    manualAddressEntryText() {
      if (this.utils.manualAddressInput) return 'Close address form'
      else return "Can't find it? Enter it manually"
    }
  },
  methods: {
    async getAddressData() {
      try {
        this.$wait.start('getAddress')
        const { search, utils } = this
        const request = search.query.toLowerCase().replace(/\s/g, '')
        const apiKey = 'LHH0dAHAKEOM5DkTJkruMw14531'
        const path = `${request}?api-key=${apiKey}&expand=true`

        const { data } = await getAddress.get(path)
        const { postcode, addresses } = data

        const results = addresses.map(address => {
          const line1 = address.line_1 ? `${address.line_1}, ` : ''
          const line2 = address.line_2 ? `${address.line_2}, ` : ''
          const city = address.town_or_city ? `${address.town_or_city}` : ''

          return { ...address, label: line1 + line2 + city, postcode }
        })

        search.results = results
        utils.showSearch = false
        utils.manualAddressInput = false
      } catch {
        const msg = 'Unable to find postcode, please add your details manually'
        this.$notify(msg)
        this.utils.manualAddressInput = true
      } finally {
        this.$wait.end('getAddress')
      }
    },
    openAddressForm() {
      this.utils.manualAddressInput = true
    },
    completeForm(address) {
      this.form.address1 = address.line_1
      this.form.address2 = address.line_2
      this.form.city = address.town_or_city
      this.form.postcode = address.postcode
      this.form.country = address.country
    },
    async submit() {
      const valid = await this.$validator.validate()

      if (!valid) {
        this.utils.manualAddressInput = true
        return this.$notify('Please complete all required fields')
      }

      try {
        this.$wait.start('loading')
        this.$store.commit('auth/signup/SET_ORG', this.form)
        const data = await this.$store.dispatch('auth/signup/submit')
        this.$emit('done', { exists: !!data })
      } catch (err) {
        const fallback = 'Something went wrong. We have been notified.'
        this.$notify(err?.response?.data?.error?.message || fallback)
      } finally {
        this.$wait.end('loading')
      }
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="sass">
#postcode
  input.multiselect__input
    font-size: 1em
    min-height: 100%
    padding: 0
    margin: 0
  .multiselect
    min-width: 16rem
    max-width: 100%
    height: 45px
    min-height: 45px
    .multiselect__option--selected
      background: #ccc
      color: #fff
      &.multiselect__option--highlight
        background: #ccc
        cursor: not-allowed
  .multiselect__option
    &.multiselect__option--highlight
      background: $info
  .multiselect__placeholder
    font-size: 1em
    line-height: 1.75
    margin: 0
    padding: 0
    height: 100%
  .multiselect__single
    font-size: 1em
    margin: 4px 0px 0px 0px
    padding: 0
    height: 100%
  .multiselect__tags
    padding: 8px 0px 8px 11px
    height: 100%
  .multiselect__select
    height: 100%
</style>
